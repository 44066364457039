<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" xl="10">
                <custom-page-title page-title="menu.help.company-registration.fee"></custom-page-title>
                <h2>Az illeték és a költségtérítés megfizetése</h2>

                <p>Az illeték, valamint a költségtérítés megfizetésének módja a
                    következő:</p>

                <div><b>A <a href="https://illetekigazolas.allamkincstar.gov.hu/" target="_blank">Magyar
                    Államkincstár honlapján</a> lekért utalványminta segítségével, átutalással:</b>

                    <ul>
                        <li>Illeték és költségtérítés utalványminta kérése a
                            Magyar Államkincstár honlapján.
                        </li>

                        <li>Az utalványminta kérése során a befizetés igazolására
                            irányuló kérelmet a rendszer automatikusan létrehozza, ezt a bejegyzést kérő
                            jogi képviselőnek minősített elektronikus aláírásával kell ellátnia
                        </li>

                        <li>Az igazoláskérelem aláírása után az utalványmintán szereplő
                            adatok szerint banki átutalással kell megfizetni az illetéket és a
                            költségtérítést. Fontos, hogy a közleményben pontosan tüntesse fel a 16 jegyű
                            ügyazonosítót.
                        </li>

                        <li>A Magyar Államkincstár a befizetés megérkezte után 1
                            munkanapon belül fokozott biztonságú elektronikus aláírással ellátott
                            elektronikus igazolást küld emailben a befizetésről.

                            <ul>
                                <li>Elektronikus cégeljárás esetén a MÁK által küldött
                                    elektronikus igazolást kell mellékelni a cégbejegyzési kérelemhez. Figyelem! Ne
                                    az elektronikus igazolásban lévő html fájlt
                                    mellékelje, hanem a teljes es3 kiterjesztésű e-aktát, különben az igazoláson
                                    lévő elektronikus aláírás elveszik
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <p></p>

                <div><b>Az <a href="https://fizetes.im.gov.hu/" target="_blank">Igazságügyi Fizetési
                    Portálon</a>, bankkártyával:</b>

                    <ul>
                        <li>A Fizetési Portálon történt fizetést követően a portál egy
                            digitálisan aláírt '.x132' kiterjesztésű állományt hoz létre, amelyet szintén
                            változatlan formában kell csatolni az e-aktába.
                        </li>
                    </ul>
                </div>
                <p></p>

                <h2>Fel nem használt jóváírások</h2>
                <div>A 25/2006. (V. 18.) IM rendelet 9. §-a alapján lehetőség van a befizetett, azonban fel nem használt
                    <ul>
                        <li>illeték</li>
                        <li>költségtérítés</li>
                    </ul>
                    visszautaltatására.
                </div>
                <p></p>
                <h3>A Cégbíróság számlájára befizetett, fel nem használt <i>illeték</i> visszaigénylése (10032000-0101XXXX)</h3>
                <div>Az illetékes cégbíróság számlájára befizetett, azonban fel nem használt illeték visszautaltatása az alábbiak szerint kérhető:
                    <ol>
                        <li>Az illetékes cégbíróság részére papír alapon vagy az e-cégeljárásban kialakult eljárási rend szerint (e-akta) elektronikus úton kérelmet kell benyújtani, amelyhez csatolni szükséges az illeték befizetésének megtörténtéről szóló elektronikus igazolást vagy annak papíralapú formában kiállított hiteles másolatát. Az illetékes cégbíróság a benyújtott kérelem alapján igazolást ad ki a számlájára befizetett, fel nem használt illetékről.</li>
                        <li>Tekintettel arra, hogy az illeték befizetése a cégbíróság székhelye szerinti illetékes állami adóhatóság számláján kerül rögzítésre, annak visszaigényléséhez írásbeli kérelem továbbá a cégbíróság igazolása szükséges.</li>
                    </ol>
                </div>
                <p></p>
                <p>Az illeték visszautalásáról bővebb tájékoztatás érdekében kérem forduljanak az illetékes cégbírósághoz.</p>
                <h3>IM számlájára befizetett, fel nem használt <i>költségtérítési díj</i> visszaigénylése (10032000-01810039)</h3>
                <div>Az IM számlájára befizetett, azonban fel nem használt költségtérítési díj visszautalásához szükséges írásbeli kérelmet - formanyomtatvány letölthető a <a href="http://ceginformaciosszolgalat.kormany.hu/nyomtatvanyok" target="blank_">http://ceginformaciosszolgalat.kormany.hu/nyomtatvanyok</a> oldalon - a befizetés megtörténtéről szóló elektronikus igazolás csatolásával a következő címek egyikére kell megküldeni:
                    <ul>
                        <li>postai úton: Igazságügyi Minisztérium, Bevételkezelő és Nyilvántartó Osztály, 1357 Budapest Pf. 2.</li>
                        <li>e-mailben: <a href="mailto:koltsegterites@im.gov.hu">koltsegterites@im.gov.hu</a></li>
                    </ul>
                </div>
                <p></p>
                <p><strong>Figyelem!</strong> A fel nem használt jóváírások visszaigénylésével kapcsolatban sem a Magyar Államkincstár, sem a Microsec zrt. nem illetékes.</p>
                <h3>Mit tartalmazzon az ügyfél kérelme</h3>
                <p>A 25/2006. IM rendelet értelmében az ügyfélnek a visszaigényléshez csatolnia kell a kincstár által küldött igazolást a befizetésről.</p>
                <p>Amennyiben nem küldött a kincstár igazolást, akkor értelemszerűen az ügyfél nem tudja csatolni az elektronikus igazolást, amit a 25/2006. IM rendelet 9. §-a megkövetel. (A kincstár abban az esetben nem küld igazolást, ha a befizetés során elírás történt, vagy az ügyfél nem követte a befizetésnél a 25/2006. IM rendelet rendelkezéseit.) </p>
                <div>Ebben az esetben a befizetés azonosításához az ügyfélnek minimálisan a következő adatokat kell megadnia:
                    <ul>
                        <li>Átutalás dátuma</li>
                        <li>Kedvezményezett számla száma</li>
                        <li>Átutalt összeg</li>
                        <li>Az átutalást indító számla száma</li>
                    </ul>
                </div>
                <p></p>

                <p>Célszerű, ha a fentieken túl az átutalást indító számlatulajdonos nevét és a közlemény szövegét vagy az ügyazonosítót is megadják, továbbá azon adatokat ahová a visszautalást várják. </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";
import CustomPageTitle from "@/components/CustomPageTitle";

@Component({
    components: {
        CustomPageTitle
    },

})
export default class HelpFee extends Vue {
}
</script>

<style scoped>

</style>